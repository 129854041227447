import * as React from "react";
import Layout from '../components/Layout';
import { Link } from 'gatsby';
// import "../styles/screens/Portfolio.scss";
// import {PortfolioGrid} from "../components/PortfolioGrid";

const NotFound = () => {
  // const [from, setFrom] = React.useState<string>('Бендеры');
  return (
    <Layout>
      <section className="py-5 my-5 d-flex justify-content-center">
        <div className="container">
          <div className="description text-center">
            <h2 className="title animated fadeInUp slow">Ууупсс, страница не найденна</h2>
            <Link to="/" className="link animated fadeInUp slow">Вернутся на главную</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFound;